#notifications{
  position: fixed;
  bottom: 170px;
  left: 20px;
  z-index: 10000;

  .notification {
    position: static;
    margin-bottom: 10px;

    .ui.error.message, .ui.success.message {
      background: rgba(255, 0, 0, 0.8);
      box-shadow: none;
      padding: 5px 10px;

      span {
        color: white;
        display: block;
        font-size: 12px;

        &.title {
          font-size: 16px;
          text-transform: capitalize;
          font-weight: normal;
        }

        &.id {
          font-size: 12px;
          text-transform: none;
          display: inline-block;
        }
      }
    }
    .ui.success.message {
      background: rgba(33, 186, 69, 0.8);
    }
  }
  @media screen and (max-width: 500px) {
    bottom: 210px;
  }
}