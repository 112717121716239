.ui {
  &.button {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: none;
    border: none;
    vertical-align: baseline;
    background: #E0E1E2 none;
    color: rgba(0, 0, 0, 0.6);
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0em 0.25em 0em 0em;
    padding: 0.78571429em 1.5em 0.78571429em;
    text-transform: none;
    text-shadow: none;
    font-weight: bold;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
    user-select: none;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
    will-change: '';
    -webkit-tap-highlight-color: transparent;
    &.green {
      background-color: #DE0E42;
      color: #FFFFFF;
      text-shadow: none;
      background-image: none;
      &:focus, &:hover, &:active {
        background-color: #bd0a37;
        color: #FFFFFF;
        text-shadow: none;
      }
    }
    &.inverted {
      box-shadow: 0px 0px 0px 2px #FFFFFF inset !important;
      background: transparent none;
      color: #FFFFFF;
      text-shadow: none !important;
      &:focus, &:hover, &:active {
        background: #FFFFFF;
        box-shadow: 0px 0px 0px 2px #FFFFFF inset !important;
        color: rgba(0, 0, 0, 0.8);
      }
    }
    &.right {
      float: right;
    }
  }
}