.negative {
  z-index: 10;
}
.dialog-gdpr {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 5;
  width: calc(100% - 110px);

  .ui.message {
    background: #ffffff;
    width: 100%;
    .title {
      font-weight: bold;
    }
  
    .content {
      height: 70px;
      overflow: auto;
      margin-bottom: 10px;
    }
    
  
    .buttons {
      float: right;
      .ui.button.green {
        border: 2px solid #DE0E42;
        &.outline {
          background: transparent;
          color: #DE0E42;
        }
      }
    }
  }
}