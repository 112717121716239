// @import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');
*, :after, :before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
a {
	color: #20126f;
	text-decoration: none;
	-webkit-transition: all 0.5s ease;
	transition: all .5s ease;
}
a:hover {
	color: #21ba45;
	text-decoration: none;
	transition: all .5s ease;
}
a:focus {
	outline: none;
	text-decoration: none;
}
body {
  font-family: 'Poppins', sans-serif;
}
.i.icon {
  font-family: Icons;
}
.ui.container {
  @media screen and (max-width: 767px) {
    padding-top: 56px;
  }
}

.ui.button.right {
  float: right;
}

input:focus {
  outline: none;
}

