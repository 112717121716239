    /*
    Flaticon icon font: Flaticon
    Creation date: 06/12/2019 11:07
    */

    @font-face {
      font-family: "Flaticon";
      src: url("../../assets/fonts/Flaticon-new.eot");
      src: url("../../assets/fonts/Flaticon-new.eot?#iefix") format("embedded-opentype"),
           url("../../assets/fonts/Flaticon-new.woff2") format("woff2"),
           url("../../assets/fonts/Flaticon-new.woff") format("woff"),
           url("../../assets/fonts/Flaticon-new.ttf") format("truetype"),
           url("../../assets/fonts/Flaticon-new.svg#Flaticon") format("svg");
      font-weight: normal;
      font-style: normal;
      font-display: auto;
    }
    
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      @font-face {
        font-family: "Flaticon";
        src: url("../../assets/fonts/Flaticon-new.svg#Flaticon") format("svg");
        font-display: auto;
      }
    }

  .fi:before{
    display: inline-block;
font-family: "Flaticon";
font-style: normal;
font-weight: normal;
font-variant: normal;
line-height: 1;
text-decoration: inherit;
text-rendering: optimizeLegibility;
text-transform: none;
-moz-osx-font-smoothing: grayscale;
-webkit-font-smoothing: antialiased;
font-smoothing: antialiased;
}

.flaticon-male:before { content: "\f100"; }
.flaticon-key:before { content: "\f101"; }
.flaticon-menu:before { content: "\f102"; }
.flaticon-telegram:before { content: "\f103"; }
.flaticon-video:before { content: "\f104"; }
.flaticon-logo:before { content: "\f105"; }
.flaticon-medium:before { content: "\f106"; }
.flaticon-twitter:before { content: "\f107"; }
.flaticon-facebook:before { content: "\f108"; }
.flaticon-long:before { content: "\f109"; }
.flaticon-fast-forward:before { content: "\f10a"; }
.flaticon-tick:before { content: "\f10b"; }

$font-Flaticon-male: "\f100";
$font-Flaticon-key: "\f101";
$font-Flaticon-menu: "\f102";
$font-Flaticon-telegram: "\f103";
$font-Flaticon-video: "\f104";
$font-Flaticon-logo: "\f105";
$font-Flaticon-medium: "\f106";
$font-Flaticon-twitter: "\f107";
$font-Flaticon-facebook: "\f108";
$font-Flaticon-long: "\f109";
$font-Flaticon-fast-forward: "\f10a";
$font-Flaticon-tick: "\f10b";