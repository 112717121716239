#loggedIn {
  #activate-thankyou {
    text-align: center;
    height: 40vh;
    margin-top: 15vh;
    display: block;
    h1 {
      font-weight: 700;
      span {
        color: #21ba45;
      }
    }
    img {
      max-height: 150px;
      margin: 35px auto;
    }
    p {
      font-size: 18px;
    }
  }
}

#loggedOut {
  #activate-thankyou {
    text-align: center;
    flex-flow:column;
    img {
      max-height: 150px;
      margin: 35px auto;
    }
    p {
      font-size: 18px;
    }
    .ui.button.green {
      align-self: center;
    }
  }
}