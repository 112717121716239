.courses-list-v2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .course-lesson-container {
    text-align: center;
    padding: 20px;
    width: 33%;
    margin-bottom: 50px;
    &:nth-child(5n + 4), &:nth-child(5n) {
      width: 50%;
    }
    .course-lesson {
      cursor: pointer;
      .lesson-image-container {
        width: 120px;
        height: 120px;
        box-sizing: border-box;
        margin: auto;
        border-radius: 80px;
        background-color: #f6f6f6;
        border: 10px solid #f6f6f6;
        .lesson-image {
          width: auto;
          height: 100%;
          max-width: 80%;
          max-height: 100%;
          padding: 10%;
        }
        &.finished {
          position: relative;
          &:before {
            content: '\F00C';
            font-family: 'Icons';
            color: #21ba45;
            font-size: 32px;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        @media screen and (max-width: 650px) {
          width: 80px;
          height: 80px;
        }
      }
      .lesson-title {
        display: block;
        margin-top: 5px;
      }
      &:hover {
        font-weight: bold;
        .lesson-image-container {
          border: 3px solid #f2f2f2;
        }
      }
    }
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    :nth-child(even) {
      align-self: flex-end;
    }
    .course-lesson-container {
      width: 50%;
    }
  }
}
